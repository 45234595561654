/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";

const Home = ({ translate, lang }) => {
    const carousel = () => {
        window.$(".home-carousel-1").owlCarousel({
            loop: !0,
            margin: 0,
            nav: !0,
            dots: !1,
            navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 2
                },
                767: {
                    items: 3
                },
                1e3: {
                    items: 4
                },
                1200: {
                    items: 5
                }
            }
        })
    }
    const [about, setAbout] = useState([]);
    const [project, setProject] = useState([]);
    const [services, setServices] = useState([]);
    const [staff, setStaff] = useState([]);
    const [posts, setPosts] = useState([]);
    const [clients, setClients] = useState([]);
    const [stats, setStats] = useState([]);
    const getData = async () => {
        const { data: about } = await axios.get('/api/about');
        setAbout(about)
        const { data: project } = await axios.get('/api/projects');
        setProject(project)
        if (project) { setTimeout(() => { carousel(); }, 1000); }
        const { data: services } = await axios.get('/api/services');
        setServices(services)
        const { data: staff } = await axios.get('/api/staff');
        setStaff(staff)
        const { data: posts } = await axios.get('/api/posts?limit=true');
        setPosts(posts)
        const { data: clients } = await axios.get('/api/clients');
        setClients(clients)
        const { data: stat } = await axios.get('/api/stats');
        setStats(stat)
    }

    useEffect(() => {
        getData();
        setTimeout(() => {
            if (lang !== "en") {
                $('.wt-post-info').css({ textAlign: "right" })
                $('.section-head').css({ direction: "rtl" })
            }
        }, 1000);
    }, [])

    const [slider, setSlider] = useState([])
    const getSlider = async () => {
        const { data } = await axios.get('/api/sliders');
        setSlider(
            <ul>
                <li data-index="rs-100" data-transition="slideoverhorizontal" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed={1000} data-thumb={`../../${data[0].image}`} data-rotate={0} data-fstransition="fade" data-fsmasterspeed={1500} data-fsslotamount={7} data-saveperformance="off" data-title={lang === "krd" ? data[0].caption_krd : lang === "ar" ? data[0].caption_ar : data[0].caption_en} data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                    <img src={`../../${data[0].image}`} alt="sam" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10} className="rev-slidebg" data-no-retina />
                    <div className="tp-caption tp-shape tp-shapewrapper  " id="slide-100-layer-1" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','0','0']" data-width="full" data-height="['400','400','400','550']" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:100,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:0,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 5, backgroundColor: 'rgba(0, 0, 0, 0.50)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0, background: 'linear-gradient(to top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%)', cursor: 'default' }}>
                    </div>
                    <div className="tp-caption tp-shape tp-shapewrapper  " id="slide-100-layer-2" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-width="full" data-height="['400','400','400','550']" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 5, backgroundColor: 'rgba(0, 0, 0, 0.50)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0, background: 'linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)', cursor: 'default' }}>
                    </div>
                    <div className="tp-caption BigBold-Title   tp-resizeme" id="slide-100-layer-3" data-x="['left','left','left','left']" data-hoffset="['50','50','30','17']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['110','110','180','180']" data-fontsize="['90','70','50','30']" data-lineheight="['100','90','60','60']" data-fontweight="['800','800','800','800']" data-width="['none','none','none','400']" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide" data-responsive_offset="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:500,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},
{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[10,10,10,10]" data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 6, textTransform: 'uppercase', color: '#fff' }}><span className="site-text-primary">  {lang === "krd" ? data[0].caption_krd : lang === "ar" ? data[0].caption_ar : data[0].caption_en}</span> {lang === "krd" ? data[0].title_krd : lang === "ar" ? data[0].title_ar : data[0].title_en} </div>
                    <div className="tp-caption BigBold-SubTitle  " id="slide-100-layer-4" data-x="['left','left','left','left']" data-hoffset="['55','55','33','20']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['40','1','74','78']" data-fontsize="['15','15','15','13']" data-lineheight="['24','24','24','20']" data-width="['410','410','410','280']" data-height="['60','100','100','100']" data-whitespace="normal" data-type="text" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:650,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 7, color: '#fff' }}>{lang === "krd" ? data[0].description_krd : lang === "ar" ? data[0].description_ar : data[0].description_en}
                    </div>
                    <div className="tp-caption BigBold-Button rev-btn " id="slide-100-layer-5" data-x="['left','left','left','left']" data-hoffset="['480','480','30','20']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['35','35','35','35']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="button" data-actions="[{&quot;event&quot;:&quot;click&quot;,&quot;action&quot;:&quot;scrollbelow&quot;,&quot;offset&quot;:&quot;px&quot;,&quot;delay&quot;:&quot;&quot;}]" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:650,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;frame&quot;:&quot;hover&quot;,&quot;speed&quot;:&quot;300&quot;,&quot;ease&quot;:&quot;Power1.easeInOut&quot;,&quot;to&quot;:&quot;o:1;rX:0;rY:0;rZ:0;z:0;&quot;,&quot;style&quot;:&quot;c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[15,15,15,15]" data-paddingright="[50,50,50,50]" data-paddingbottom="[15,15,15,15]" data-paddingleft="[50,50,0,0]" style={{ zIndex: 8 }}></div>
                </li>
                <li data-index="rs-200" data-transition="slideoverhorizontal" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed={1000} data-thumb={`../../${data[1].image}`} data-rotate={0} data-fstransition="fade" data-fsmasterspeed={1500} data-fsslotamount={7} data-saveperformance="off" data-title={lang === "krd" ? data[1].caption_krd : lang === "ar" ? data[1].caption_ar : data[1].caption_en} data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                    <img src={`../../${data[1].image}`} alt="sam" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10} className="rev-slidebg" data-no-retina />
                    <div className="tp-caption tp-shape tp-shapewrapper  " id="slide-200-layer-1" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','0','0']" data-width="full" data-height="['400','400','400','550']" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:100,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:0,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 5, backgroundColor: 'rgba(0, 0, 0, 0.50)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0, background: 'linear-gradient(to top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%)', cursor: 'default' }}>
                    </div>
                    <div className="tp-caption tp-shape tp-shapewrapper  " id="slide-200-layer-2" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-width="full" data-height="['400','400','400','550']" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 5, backgroundColor: 'rgba(0, 0, 0, 0.50)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0, background: 'linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)', cursor: 'default' }}>
                    </div>
                    <div className="tp-caption BigBold-Title   tp-resizeme" id="slide-200-layer-3" data-x="['left','left','left','left']" data-hoffset="['50','50','30','17']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['110','110','180','180']" data-fontsize="['90','70','50','30']" data-lineheight="['100','90','60','60']" data-fontweight="['800','800','800','800']" data-width="['none','none','none','400']" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide" data-responsive_offset="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:500,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},
{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[10,10,10,10]" data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 6, textTransform: 'uppercase', color: '#fff' }}><span className="site-text-primary">{lang === "krd" ? data[1].caption_krd : lang === "ar" ? data[1].caption_ar : data[1].caption_en}</span> {lang === "krd" ? data[1].title_krd : lang === "ar" ? data[1].title_ar : data[1].title_en}</div>
                    <div className="tp-caption BigBold-SubTitle  " id="slide-200-layer-4" data-x="['left','left','left','left']" data-hoffset="['55','55','33','20']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['40','1','74','78']" data-fontsize="['15','15','15','13']" data-lineheight="['24','24','24','20']" data-width="['410','410','410','280']" data-height="['60','100','100','100']" data-whitespace="normal" data-type="text" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:650,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 7, color: '#fff' }}>{lang === "krd" ? data[1].description_krd : lang === "ar" ? data[1].description_ar : data[1].description_en}
                    </div>
                    <div className="tp-caption BigBold-Button rev-btn " id="slide-200-layer-5" data-x="['left','left','left','left']" data-hoffset="['480','480','30','20']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['35','35','35','35']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="button" data-actions="[{&quot;event&quot;:&quot;click&quot;,&quot;action&quot;:&quot;scrollbelow&quot;,&quot;offset&quot;:&quot;px&quot;,&quot;delay&quot;:&quot;&quot;}]" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:650,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;frame&quot;:&quot;hover&quot;,&quot;speed&quot;:&quot;300&quot;,&quot;ease&quot;:&quot;Power1.easeInOut&quot;,&quot;to&quot;:&quot;o:1;rX:0;rY:0;rZ:0;z:0;&quot;,&quot;style&quot;:&quot;c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[15,15,15,15]" data-paddingright="[50,50,50,50]" data-paddingbottom="[15,15,15,15]" data-paddingleft="[50,50,0,0]" style={{ zIndex: 8 }}> </div>
                </li>
                <li data-index="rs-300" data-transition="slideoverhorizontal" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed={1000} data-thumb={`../../${data[2].image}`} data-rotate={0} data-fstransition="fade" data-fsmasterspeed={1500} data-fsslotamount={7} data-saveperformance="off" data-title={lang === "krd" ? data[2].caption_krd : lang === "ar" ? data[2].caption_ar : data[2].caption_en} data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                    <img src={`../../${data[2].image}`} alt="sam" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10} className="rev-slidebg" data-no-retina />
                    <div className="tp-caption tp-shape tp-shapewrapper  " id="slide-300-layer-1" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','0','0']" data-width="full" data-height="['400','400','400','550']" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:100,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:0,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 5, backgroundColor: 'rgba(0, 0, 0, 0.50)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0, background: 'linear-gradient(to top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%)', cursor: 'default' }}>
                    </div>
                    <div className="tp-caption tp-shape tp-shapewrapper  " id="slide-300-layer-2" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-width="full" data-height="['400','400','400','550']" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 5, backgroundColor: 'rgba(0, 0, 0, 0.50)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0, background: 'linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)', cursor: 'default' }}>
                    </div>
                    <div className="tp-caption BigBold-Title   tp-resizeme" id="slide-300-layer-3" data-x="['left','left','left','left']" data-hoffset="['50','50','30','17']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['110','110','180','180']" data-fontsize="['90','70','50','30']" data-lineheight="['100','90','60','60']" data-fontweight="['800','800','800','800']" data-width="['none','none','none','400']" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide" data-responsive_offset="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:500,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},
{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:[100%];&quot;,&quot;mask&quot;:&quot;x:inherit;y:inherit;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[10,10,10,10]" data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 6, textTransform: 'uppercase', color: '#fff' }}><span className="site-text-primary"> {lang === "krd" ? data[2].caption_krd : lang === "ar" ? data[2].caption_ar : data[2].caption_en}</span> {lang === "krd" ? data[2].title_krd : lang === "ar" ? data[2].title_ar : data[2].title_en}</div>
                    <div className="tp-caption BigBold-SubTitle  " id="slide-300-layer-4" data-x="['left','left','left','left']" data-hoffset="['55','55','33','20']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['40','1','74','78']" data-fontsize="['15','15','15','13']" data-lineheight="['24','24','24','20']" data-width="['410','410','410','280']" data-height="['60','100','100','100']" data-whitespace="normal" data-type="text" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:650,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 7, color: '#fff' }}>{lang === "krd" ? data[2].description_krd : lang === "ar" ? data[2].description_ar : data[2].description_en}
                    </div>
                    <div className="tp-caption BigBold-Button rev-btn " id="slide-300-layer-5" data-x="['left','left','left','left']" data-hoffset="['480','480','30','20']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['35','35','35','35']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="button" data-actions="[{&quot;event&quot;:&quot;click&quot;,&quot;action&quot;:&quot;scrollbelow&quot;,&quot;offset&quot;:&quot;px&quot;,&quot;delay&quot;:&quot;&quot;}]" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:650,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;frame&quot;:&quot;hover&quot;,&quot;speed&quot;:&quot;300&quot;,&quot;ease&quot;:&quot;Power1.easeInOut&quot;,&quot;to&quot;:&quot;o:1;rX:0;rY:0;rZ:0;z:0;&quot;,&quot;style&quot;:&quot;c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[15,15,15,15]" data-paddingright="[50,50,50,50]" data-paddingbottom="[15,15,15,15]" data-paddingleft="[50,50,0,0]" style={{ zIndex: 8 }}></div>
                </li>
            </ul>
        )
    }

    useEffect(() => {
        getSlider();
    }, [])

    return (
        <div className="page-content">
            <div className="main-slider style-two default-banner">
                <div className="tp-banner-container">
                    <div className="tp-banner">
                        <div id="rev_slider_1077_1_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="scroll-effect136" data-source="gallery" style={{ backgroundColor: '#111111', padding: 0 }}>
                            <div id="rev_slider_1077_1" className="rev_slider fullscreenbanner" style={{ display: 'none' }} data-version="5.4.1">
                                {slider}
                                <div className="tp-bannertimer tp-bottom" style={{ visibility: 'hidden !important' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t80 p-b50 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-8 m-b30">
                            <div className="about-com-pic">
                                <img src={`../../${about.image}`} alt="sam" className="img-responsive" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 m-b30">
                            <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? about.content_krd : lang === "ar" ? about.content_ar : about.content_en }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t50 p-b50 overlay-wraper bg-parallax" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(assets/images/background/bg2.jpg)' }}>
                <div className="overlay-main opacity-07 bg-black" />
                <div className="container">
                    <div className="section-head">
                        <div className="container">
                            <h2 className="text-uppercase text-white">{translate("latest_project", lang)} </h2>
                            <div className="wt-separator-outer">
                                <div className="wt-separator style-square has-bg">
                                    <span className="separator-left site-bg-primary" />
                                    <span className="separator-right site-bg-primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="section-content">
                        <div className="owl-carousel home-carousel-1 mfp-gallery gallery owl-btn-vertical-center m-b30">
                            {project.map((item, index) => {
                                return (
                                    <div className="item" key={index}>
                                        <div className="flip-container">
                                            <div className="wt-box">
                                                <div className="wt-thum-bx">
                                                    <img src={`../../${item.image}`} alt="sam" />
                                                </div>
                                                <div className="wt-info bg-white text-center bdr-5 bdr-primary">
                                                    <div className="wt-info-text p-a30">
                                                        <span><i className="flaticon-golden-gate-bridge site-text-primary" /></span>
                                                        <h3 className="text-uppercase">{lang === "krd" ? item.title_krd : lang === "ar" ? item.title_ar : item.title_en}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? item.description_krd : lang === "ar" ? item.description_ar : item.description_en }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full bg-gray p-t80 p-b50">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase">{translate("awesome_services", lang)}</h2>
                        <div className="wt-separator-outer">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                    </div>
                    <div className="section-content m-b20">
                        <div className="row">
                            {services.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-6 m-b10 animate_line">
                                        <div className="wt-icon-box-wraper  p-a30 center bg-white">
                                            <div className="icon-sm site-text-primary m-b20">
                                                <a href="about-1.html" className="icon-cell"> <div dangerouslySetInnerHTML={{ __html: item.icon }} /></a>
                                            </div>
                                            <div className="icon-content">
                                                <h5 className="wt-tilte text-uppercase">{lang === "krd" ? item.title_krd : lang === "ar" ? item.title_ar : item.title_en}</h5>
                                                <p>{lang === "krd" ? item.description_krd : lang === "ar" ? item.description_ar : item.description_en}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t50 p-b50 overlay-wraper bg-parallax" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(../../assets/images/background/bg-5.jpg)' }}>
                <div className="overlay-main opacity-07 bg-black" />
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="text-center text-white">
                                <h3 className="font-24">{translate("awesome_facts", lang)}</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="status-marks  text-white m-b30">
                                        <div className="status-value text-right">
                                            <span className="counter">{stats.project}</span>
                                            <i className={`fa fa-building font-26 m-l15`} />
                                        </div>
                                        <h6 className="text-uppercase text-white text-right">{translate("project_completed", lang)}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="status-marks  text-white m-b30">
                                        <div className="status-value text-right">
                                            <span className="counter">{stats.client}</span>
                                            <i className={`fa fa-users font-26 m-l15`} />
                                        </div>
                                        <h6 className="text-uppercase text-white text-right">{translate("happy_clients", lang)}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="status-marks  text-white m-b30">
                                        <div className="status-value text-right">
                                            <span className="counter">{stats.staff}</span>
                                            <i className={`fa fa-user-plus font-26 m-l15`} />
                                        </div>
                                        <h6 className="text-uppercase text-white text-right">{translate("workers_employed", lang)}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full wt-our-team bg-white p-t80 p-b50">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase">{translate("our_team", lang)}</h2>
                        <div className="wt-separator-outer">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                            {staff.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                        <div className="wt-team-four">
                                            <div className="wt-team-media">
                                                <a href="/staff">
                                                    <img src={`../../${item.image}`} alt="sam" />
                                                </a>
                                            </div>
                                            <div className="wt-team-info">
                                                <div className="wt-team-skew-block">
                                                    <div className="p-a20">
                                                        <ul className="social-icons text-center">
                                                            <li><span className="text-white"><i className="fa fa-phone" />&nbsp; {item.phone_no}</span> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="p-a20">
                                                    <h4 className="wt-team-title text-uppercase text-center"><Link to="/staff">{lang === "krd" ? item.name_krd : lang === "ar" ? item.name_ar : item.name_en}</Link></h4>
                                                    <p>{lang === "krd" ? item.role_krd : lang === "ar" ? item.role_ar : item.role_en}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full latest-blog bg-gray p-t80 p-b50">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase">{translate("latest_blog_post", lang)}</h2>
                        <div className="wt-separator-outer">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                            {posts.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="blog-post latest-blog-1 date-style-3">
                                            <div className="wt-post-media wt-img-effect zoom-slow">
                                                <Link to={`/post/${item.id}`}>
                                                    <img src={`../../${item.thumbnail}`} alt="sam" />
                                                </Link>
                                            </div>
                                            <div className="wt-post-info p-a30 p-b20 bg-white">
                                                <div className="wt-post-title">
                                                    <h3 className="post-title"><Link to={`/post/${item.id}`}>{lang === "krd" ? item.title_krd : lang === "ar" ? item.title_ar : item.title_en}</Link></h3>
                                                </div>
                                                <div className="wt-post-meta">
                                                    <ul>
                                                        <li className="post-date"><i className="fa fa-calendar" />{new Date(item.created).toLocaleDateString()}</li>
                                                    </ul>
                                                </div>
                                                <div className="wt-post-text">
                                                    <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? item.content_krd : lang === "ar" ? item.content_ar : item.content_en }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase">{translate("our_client", lang)}</h2>
                        <div className="wt-separator-outer">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                    </div>
                    <div className="section-content m-b30">
                        <div className="owl-carousel home-carousel-1">
                            {clients.map((item, index) => {
                                return (
                                    <div key={index} className="item">
                                        <div className="ow-client-logo">
                                            <div className="client-logo wt-img-effect on-color">
                                                <img src={`../../${item.logo}`} alt="sam" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;

/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Blog = ({ translate, lang }) => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/api/posts');
        setData(data)
    }
    useEffect(() => {
        getData();

        if (lang !== "en") {
            $('.text-white').css({ direction: "rtl" })
            $('.wt-breadcrumb').css({ direction: "rtl" })
            setTimeout(() => {
                $('.wt-post-info').css({ textAlign: "right" })
            }, 1000);
        }
    }, [])
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper" style={{ backgroundImage: 'url(../../assets/images/banner/blog-banner.jpg)' }}>
                <div className="overlay-main bg-black opacity-07" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <h1 className="text-white">{translate("latest_blog_post", lang)}</h1>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-tb20">
                <div className="container">
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/"><i className="fa fa-home" /> {translate("home", lang)}</a></li>
                        <li>{translate("blog", lang)}</li>
                    </ul>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase">{translate("latest_blog_post", lang)}</h2>
                        <div className="wt-separator-outer">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                    </div>
                    <div className="section-content ">
                        <div className='row'>
                            {data.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="blog-post latest-blog-1 date-style-3">
                                            <div className="wt-post-media wt-img-effect zoom-slow">
                                                <Link to={`/post/${item.id}`}><img src={`../../${item.thumbnail}`} alt="sam" /></Link>
                                            </div>
                                            <div className="wt-post-info p-a30 p-b20 bg-white">
                                                <div className="wt-post-title ">
                                                    <h3 className="post-title"><Link to={`/post/${item.id}`}>{lang === "krd" ? item.title_krd : lang === "ar" ? item.title_ar : item.title_en}</Link></h3>
                                                </div>
                                                <div className="wt-post-meta ">
                                                    <ul>
                                                        <li className="post-date"><i className="fa fa-calendar" />{new Date(item.created).toLocaleDateString()}</li>
                                                    </ul>
                                                </div>
                                                <div className="wt-post-text">
                                                    <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? item.content_krd : lang === "ar" ? item.content_ar : item.content_en }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog;
/* eslint-disable */
import Footer from './Components/Layout/Footer';
import Header from './Components/Layout/Header';
import translate from './translation.json';

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';

const getWords = (keyword, language) => {
  const item = translate.find((item) => item.keyword === keyword);
  if (item && item.words && item.words[language]) {
    return item.words[language];
  }
  return '';
};

const APP = ({ children }) => {
  return (
    <>
      <Header translate={getWords} lang={lang} />
      {children}
      <Footer translate={getWords} lang={lang} />
    </>
  );
};

export default APP;
import { useRouteError } from "react-router-dom";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
export default function ErrorPage() {
    const error = useRouteError();
    return (
        <>
            <Header />
            <div className="page-content">
                <div className="section-full p-t80 p-b50 bg-dark">
                    <div className="container">
                        <div className="section-content page-notfound-outer">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-sm-6">
                                    <div className="page-notfound text-center">
                                        <strong className="text-uppercase text-white">Opps !</strong> <strong><i className="fa fa-frown-o site-text-primary " /></strong>
                                        <span className="text-white">{error.statusText || error.message}</span><br />
                                        <a href="/" className="site-button ">GO TO HOME <i className="fa fa-angle-double-right" /></a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="page-notfound-left text-center bg-gray">
                                        <div className="constrot-strip" />
                                        <span className="flaticon-plumber-working" />
                                        <div className="constrot-strip" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
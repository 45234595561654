/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from "axios";

const Header = ({ translate, lang }) => {
    const [data, setData] = useState([]);
    const [branch, setBranch] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/api/general');
        setData(data)
        const { data: branch } = await axios.get('/api/branches');
        setBranch(branch)
    }

    useEffect(() => {
        getData();
        if (lang !== "en") {
            $('.main-bar').css({ direction: "rtl" })
        }
    }, [])
    return (
        <header className="site-header header-style-3 mobile-sider-drawer-menu">
            <div className="sticky-header main-bar-wraper">
                <div className="main-bar site-bg-primary">
                    <div className="container">
                        <div className="logo-header mostion">
                            {data && data.log ?
                                <img src={`../../../${data.logo}`} width="171" height="49" alt="logo" /> :
                                <a href="/" style={{ fontWeight: 'bold', fontSize: 35 }}><span style={{ color: '#607d8b' }}>MY</span><span style={{ color: 'white' }}>KONOS</span></a>
                            }
                        </div>
                        <div className="header-nav navbar-collapse collapse ">
                            <ul className=" nav navbar-nav">
                                <li><a href="/">{translate("home", lang)}</a></li>
                                <li><Link to="/projects">{translate("projects", lang)}</Link></li>
                                <li><Link to="/services">{translate("services", lang)}</Link></li>
                                <li><Link to="/blog">{translate("blog", lang)}</Link></li>
                                <li><Link to="/staff">{translate("staff", lang)}</Link></li>
                                <li className="has-child">
                                    <Link to="/about">{translate("about", lang)}
                                        <i className="fa fa-chevron-down" />
                                    </Link>
                                    <div className="fa fa-angle-right submenu-toogle" />
                                    <ul className="sub-menu">
                                        {branch.map((item, index) => {
                                            return (
                                                <li key={index}><Link to={`/branch/${item.id}`}>{lang === "krd" ? item.name_krd : lang === "ar" ? item.name_ar : item.name_en}</Link></li>
                                            )
                                        })}
                                    </ul>
                                </li>
                                <li><Link to="/contact">{translate("contact_us", lang)}</Link></li>
                                <li className="has-child">
                                    <a href="#">
                                        {lang === "krd" ? "کوردی" : lang === "ar" ? "العربية" : "English"}
                                        <i className="fa fa-chevron-down" />
                                    </a>
                                    <div className="fa fa-angle-right submenu-toogle" />
                                    <ul className="sub-menu">
                                        <li><a href="#0" onClick={() => { localStorage.setItem('lang', 'krd'); window.location.reload(); }}>کوردی</a></li>
                                        <li><a href="#0" onClick={() => { localStorage.setItem('lang', 'ar'); window.location.reload(); }}>العربية</a></li>
                                        <li><a href="#0" onClick={() => { localStorage.setItem('lang', 'en'); window.location.reload(); }}>English</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first" />
                            <span className="icon-bar icon-bar-two" />
                            <span className="icon-bar icon-bar-three" />
                        </button>
                    </div>
                </div>
            </div>
        </header>

    );
}

export default Header;
/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";

const Projects = ({ translate, lang }) => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/api/projects');
        setData(data)
    }
    useEffect(() => {
        getData();
        if (lang !== "en") {
            $('.text-white').css({ direction: "rtl" })
            $('.wt-breadcrumb').css({ direction: "rtl" })
            $('.section-head').css({ direction: "rtl" })
        }
    }, [])
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper" style={{ backgroundImage: 'url(../../assets/images/banner/faq-banner.jpg)' }}>
                <div className="overlay-main bg-black opacity-07" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <h1 className="text-white">{translate("latest_project", lang)}</h1>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-tb20">
                <div className="container">
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/"><i className="fa fa-home" /> {translate("home", lang)}</a></li>
                        <li>{translate("latest_project", lang)}</li>
                    </ul>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="section-head">
                        <div className="container">
                            <h2 className="text-uppercase">{translate("latest_project", lang)}</h2>
                            <div className="wt-separator-outer">
                                <div className="wt-separator style-square has-bg">
                                    <span className="separator-left site-bg-primary" />
                                    <span className="separator-right site-bg-primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="container ">
                        <div className="row">
                            {data.map((item, index) => {
                                return (
                                    <div key={index} className="col-md-4">
                                        <div className="flip-container">
                                            <div className="wt-box">
                                                <div className="wt-thum-bx">
                                                    <img src={`../../${item.image}`} alt="sam" />
                                                </div>
                                                <div className="wt-info bg-white text-center bdr-5 bdr-primary">
                                                    <div className="wt-info-text p-a30">
                                                        <span><i className="flaticon-golden-gate-bridge site-text-primary" /></span>
                                                        <h3 className="text-uppercase">{lang === "krd" ? item.title_krd : lang === "ar" ? item.title_ar : item.title_en}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? item.description_krd : lang === "ar" ? item.description_ar : item.description_en }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;
/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";

const Post = ({ translate, lang }) => {
    const postId = useParams().id

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/api/post/${postId}`);
        setData(data)
    }
    useEffect(() => {
        getData();
        if (lang !== "en") {
            $('.text-white').css({ direction: "rtl" })
            $('.wt-breadcrumb').css({ direction: "rtl" })
        }
    }, [postId])
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper" style={{ backgroundImage: 'url(../../assets/images/banner/product-banner.jpg)' }}>
                <div className="overlay-main bg-black opacity-07" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <h1 className="text-white">
                            {lang === "krd" ? data.title_krd : lang === "ar" ? data.title_ar : data.title_en}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-tb20">
                <div className="container">
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/"><i className="fa fa-home" /> {translate("home", lang)}</a></li>
                        <li>{translate("blog", lang)}</li>
                    </ul>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 ">
                            <div className="aon-thum-bx">
                                <img src={`../../${data.thumbnail}`} alt="sam" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 m-b30">
                            <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? data.content_krd : lang === "ar" ? data.content_ar : data.content_en }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Post;
/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Staff = ({ translate, lang }) => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/api/staff');
        setData(data)
    }
    useEffect(() => {
        getData();
        if (lang !== "en") {
            $('.text-white').css({ direction: "rtl" })
            $('.wt-breadcrumb').css({ direction: "rtl" })
        }
    }, [])
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper" style={{ backgroundImage: 'url(../../assets/images/banner/about-banner.jpg)' }}>
                <div className="overlay-main bg-black opacity-07" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <h1 className="text-white">{translate("our_team", lang)}</h1>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-tb20">
                <div className="container">
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/"><i className="fa fa-home" /> {translate("home", lang)}</a></li>
                        <li>{translate("staff", lang)}</li>
                    </ul>
                </div>
            </div>
            <div className="section-full wt-our-team bg-white p-t80 p-b50">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase">{translate("our_team", lang)}</h2>
                        <div className="wt-separator-outer">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                            {data.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                        <div className="wt-team-four">
                                            <div className="wt-team-media">
                                                <a href="#/"><img src={`../../${item.image}`} alt="sam" /></a>
                                            </div>
                                            <div className="wt-team-info">
                                                <div className="wt-team-skew-block">
                                                    <div className="p-a20">
                                                        <ul className="social-icons text-center">
                                                            <li><span className="text-white"><i className="fa fa-phone" />&nbsp; {item.phone_no}</span> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="p-a20">
                                                    <h4 className="wt-team-title text-uppercase text-center"><Link to="/staff">{lang === "krd" ? data.name_krd : lang === "ar" ? data.name_ar : data.name_en}</Link></h4>
                                                    <p>{item.role_krd}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Staff;
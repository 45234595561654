/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";

const Footer = ({ translate, lang }) => {
    const [general, setGeneral] = useState([]);
    const getData = async () => {
        const { data: general } = await axios.get('/api/general');
        setGeneral(general)
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <footer className="site-footer footer-dark">
            <div className="footer-top overlay-wraper">
                <div className="overlay-main" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 p-tb20">
                            <div className="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix">
                                <div className="icon-md site-text-primary">
                                    <span className="iconmoon-travel" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="wt-tilte text-uppercase m-b0">{translate("address", lang)}</h5>
                                    <p className="m-b0">{general.address_krd}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 p-tb20 ">
                            <div className="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix ">
                                <div className="icon-md site-text-primary">
                                    <span className="iconmoon-smartphone-1" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="wt-tilte text-uppercase m-b0">{translate("phone", lang)}</h5>
                                    <p className="m-b0">{general.phone_no1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 p-tb20">
                            <div className="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix">
                                <div className="icon-md site-text-primary">
                                    <span className="iconmoon-email" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="wt-tilte text-uppercase m-b0">{translate("email", lang)}</h5>
                                    <p className="m-b0">{general.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom overlay-wraper">
                <div className="overlay-main" />
                <div className="container p-t30">
                    <div className="row">
                        <div className="wt-footer-bot-left">
                            <span className="copyrights-text">© 2023 MYKONOS.</span>
                        </div>
                        <div className="wt-footer-bot-right">
                            <ul className="copyrights-nav pull-right">
                                <li><a href="/"> <i className="fa fa-facebook" /></a></li>
                                <li><a href="/"> <i className="fa fa-youtube" /></a></li>
                                <li><a href="/"> <i className="fa fa-twitter" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;
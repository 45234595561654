/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";

const Branch = ({ translate, lang }) => {
    const branchId = useParams().id

    const [data, setData] = useState([]);
    const [tab, setTab] = useState([]);
    const [stats, setStats] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/api/about?branch=${branchId}`);
        setData(data)
        const { data: tab } = await axios.get(`/api/tabs?aboutID=${data.id}`);
        setTab(tab)
        const { data: stat } = await axios.get('/api/stats');
        setStats(stat)
    }
    useEffect(() => {
        getData();
        if (lang !== "en") {
            $('.text-white').css({ direction: "rtl" })
            $('.wt-breadcrumb').css({ direction: "rtl" })
        }
    }, [branchId])
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper" style={{ backgroundImage: 'url(../../assets/images/banner/product-banner.jpg)' }}>
                <div className="overlay-main bg-black opacity-07" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <h1 className="text-white">{translate("contact_us", lang)}</h1>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-tb20">
                <div className="container">
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/"><i className="fa fa-home" /> {translate("home", lang)}</a></li>
                        <li>{translate("about", lang)}</li>
                    </ul>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <div dangerouslySetInnerHTML={{ __html: lang === "krd" ? data.content_krd : lang === "ar" ? data.content_ar : data.content_krd }} />
                        </div>
                        <div className="col-lg-6 col-md-12 ">
                            <div className="aon-thum-bx">
                                <img src={`../../${data.image}`} alt="sam" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 m-b30">
                            <div className="section-content">
                                <div className="wt-accordion acc-bg-dark" id="accordion9">
                                    {tab.map((item, index) => {
                                        return (
                                            <div key={index} className="panel wt-panel">
                                                <div className={`acod-head ${index === 0 ? 'acc-actives' : ''}`}>
                                                    <h6 className="acod-title text-uppercase">
                                                        <a data-bs-toggle="collapse" href="/" data-bs-target={`#collapse${item.id}`}>
                                                            <i className="fa fa-globe" />
                                                            {lang === "krd" ? item.title_krd : lang === "ar" ? item.title_ar : item.title_en}
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id={`collapse${item.id}`} className={`acod-body collapse ${index === 0 ? 'show' : ''} `} data-bs-parent="#accordion9">
                                                    <div className="acod-content p-tb15">{lang === "krd" ? item.content_krd : lang === "ar" ? item.content_ar : item.content_en}</div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t50 p-b50 overlay-wraper bg-parallax" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(../../assets/images/background/bg-5.jpg)' }}>
                <div className="overlay-main opacity-07 bg-black" />
                <div className="container ">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="text-center text-white">
                                <h3 className="font-24">{translate("awesome_facts", lang)}</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="status-marks  text-white m-b30">
                                        <div className="status-value text-right">
                                            <span className="counter">{stats.project}</span>
                                            <i className={`fa fa-building font-26 ${lang !== "en" ? 'r-115' : 'm-l15'}`} />
                                        </div>
                                        <h6 className="text-uppercase text-white text-right">{translate("project_completed", lang)}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="status-marks  text-white m-b30">
                                        <div className="status-value text-right">
                                            <span className="counter">{stats.client}</span>
                                            <i className={`fa fa-users font-26 ${lang !== "en" ? 'r-115' : 'm-l15'}`} />
                                        </div>
                                        <h6 className="text-uppercase text-white text-right">{translate("happy_clients", lang)}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="status-marks  text-white m-b30">
                                        <div className="status-value text-right">
                                            <span className="counter">{stats.staff}</span>
                                            <i className={`fa fa-user-plus font-26 ${lang !== "en" ? 'r-115' : 'm-l15'}`} />
                                        </div>
                                        <h6 className="text-uppercase text-white text-right">{translate("workers_employed", lang)}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Branch;
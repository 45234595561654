import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Error from './404';
import APP from '../App';
import translate from '../translation.json';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Blog from './Pages/Blog';
import Services from './Pages/Services';
import Staff from './Pages/Staff';
import Projects from './Pages/Projects';
import Post from './Pages/Post';
import Branch from './Pages/Branch';

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';
const getWords = (keyword, language) => {
    const item = translate.find((item) => item.keyword === keyword);
    if (item && item.words && item.words[language]) {
        return item.words[language];
    }
    return '';
};
const Routes = createBrowserRouter([
    {
        path: "/",
        element: <APP><Home translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/about",
        element: <APP><About translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/branch/:id",
        element: <APP><Branch translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/contact",
        element: <APP><Contact translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/staff",
        element: <APP><Staff translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/blog",
        element: <APP><Blog translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/post/:id",
        element: <APP><Post translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/services",
        element: <APP><Services translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
    {
        path: "/projects",
        element: <APP><Projects translate={getWords} lang={lang} /></APP>,
        errorElement: <Error />,
    },
]
);

export default Routes;
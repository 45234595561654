/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";

const Contact = ({ translate, lang }) => {
    const [general, setGeneral] = useState([]);
    const getData = async () => {
        const { data: general } = await axios.get('/api/general');
        setGeneral(general)
    }
    useEffect(() => {
        getData();
        if (lang !== "en") {
            $('.text-white').css({ direction: "rtl" })
            $('.text-uppercase').css({ direction: "rtl" })
            $('.wt-breadcrumb').css({ direction: "rtl" })
        }
    }, [])
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper" style={{ backgroundImage: 'url(../../assets/images/banner/career.jpg)' }}>
                <div className="overlay-main bg-black opacity-07" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <h1 className="text-white">{translate("contact_us", lang)}</h1>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-tb20">
                <div className="container">
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/"><i className="fa fa-home" /> {translate("home", lang)}</a></li>
                        <li>{translate("contact_us", lang)}</li>
                    </ul>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="section-content m-b30">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 m-b30">
                                <div className="wt-icon-box-wraper center p-a30 bg-secondry">
                                    <div className="icon-sm text-white m-b10"><i className="iconmoon-smartphone-1" /></div>
                                    <div className="icon-content">
                                        <h5 className="text-white">{translate("phone", lang)}</h5>
                                        <p className="text-gray-dark">{general.phone_no1}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 m-b30">
                                <div className="wt-icon-box-wraper center p-a30 bg-secondry">
                                    <div className="icon-sm text-white m-b10"><i className="iconmoon-email" /></div>
                                    <div className="icon-content">
                                        <h5 className="text-white">{translate("email", lang)}</h5>
                                        <p className="text-gray-dark">{general.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 m-b30">
                                <div className="wt-icon-box-wraper center p-a30 bg-secondry">
                                    <div className="icon-sm text-white m-b10"><i className="iconmoon-travel" /></div>
                                    <div className="icon-content">
                                        <h5 className="text-white">{translate("address", lang)}</h5>
                                        <p className="text-gray-dark">{lang === "krd" ? general.address_krd : lang === "ar" ? general.address_ar : general.address_en}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-content m-b50">
                        <h4 className="text-uppercase">{translate("location", lang)}</h4>
                        <div className="wt-separator-outer m-b30">
                            <div className="wt-separator style-square">
                                <span className="separator-left site-bg-primary" />
                                <span className="separator-right site-bg-primary" />
                            </div>
                        </div>
                        <div className="gmap-outline m-b30">
                            <div id="gmap_canvas" className="google-map">
                                <div dangerouslySetInnerHTML={{ __html: general.maplink }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;